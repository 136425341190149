var exports = {};
exports = alphabetical; // Check if the given character code, or the character code at the first
// character, is alphabetical.

function alphabetical(character) {
  var code = typeof character === "string" ? character.charCodeAt(0) : character;
  return code >= 97 && code <= 122 ||
  /* a-z */
  code >= 65 && code <= 90
  /* A-Z */
  ;
}

export default exports;